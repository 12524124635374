import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = firebase.initializeApp({
  apiKey: "AIzaSyDxwBRGmZTNQGu14CR4FGs6qA8m868MQm8",
  authDomain: "todoist-clone-c5f71.firebaseapp.com",
  databaseURL: "https://todoist-clone-c5f71-default-rtdb.firebaseio.com",
  projectId: "todoist-clone-c5f71",
  storageBucket: "todoist-clone-c5f71.appspot.com",
  messagingSenderId: "70903243517",
  appId: "1:70903243517:web:8e15347431c15ca5469494",
});

export { firebaseConfig as firebase };
